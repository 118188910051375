body.page_menu { position: relative; }

.block_menu {

	position: relative;

	.sidebar {
		padding-top: 0;
		padding-bottom: 45px;
	}

	.r2t {
		z-index: 99500;
		position: fixed;
		bottom: 15px;
		right: 15px;
		width: 100%;
		//max-width: 1170px;
		margin: 0 auto;

		.button-return {
			@include make-flex;
			align-items: center;
			justify-content: center;
			width: 55px; height: 55px;
			margin-left: auto;
			border-radius: 100%;
			background-color: $gray-800;
			opacity: 0.6;
			img {
				@include transform(rotate(180deg));
				display: block;
				width: 60%;
				margin: 0 auto 5px;
			}
			&:hover { opacity: 1; }
		}

	}

	@include media-breakpoint-up(lg) {
		.sidebar { position: relative; }
		.nav-jump.affix {
			position: fixed;
			top: 20px;
			width: 220px;
		}		
	}

	@include media-breakpoint-up(xl) {
		.nav-jump.affix { width: 265px; }
	}

}