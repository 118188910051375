@-ms-viewport {
  width: device-width; }

html {
  position: relative;
  min-height: 100%; }

body {
  min-height: 100%;
  margin: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

progress {
  vertical-align: baseline; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

details {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

[hidden] {
  display: none; }

.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .wrap {
    padding-right: 0;
    padding-left: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  -ms-box-sizing: inherit;
  -o-box-sizing: inherit;
  box-sizing: inherit; }

.row.center {
  justify-content: center; }

.row.middle {
  align-items: center; }

.wrap {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.flex_container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0 1em;
  box-sizing: border-box; }
  .flex_container.gutter-fix [class*="col-"] {
    padding-left: 0 !important; }

[class*="col-"] {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding: 0 10px; }

@media (min-width: 576px) {
  .col-sm-12 {
    width: 100%; }
  .col-sm-11 {
    width: 91.66667%; }
  .col-sm-10 {
    width: 83.33333%; }
  .col-sm-9 {
    width: 75%; }
  .col-sm-8 {
    width: 66.66667%; }
  .col-sm-7 {
    width: 58.33333%; }
  .col-sm-6 {
    width: 50%; }
  .col-sm-5 {
    width: 41.66667%; }
  .col-sm-4 {
    width: 33.33333%; }
  .col-sm-3 {
    width: 25%; }
  .col-sm-2 {
    width: 16.66667%; }
  .col-sm-1 {
    width: 8.33333%; } }

@media (min-width: 768px) {
  .col-md-12 {
    width: 100%; }
  .col-md-11 {
    width: 91.66667%; }
  .col-md-10 {
    width: 83.33333%; }
  .col-md-9 {
    width: 75%; }
  .col-md-8 {
    width: 66.66667%; }
  .col-md-7 {
    width: 58.33333%; }
  .col-md-6 {
    width: 50%; }
  .col-md-5 {
    width: 41.66667%; }
  .col-md-4 {
    width: 33.33333%; }
  .col-md-3 {
    width: 25%; }
  .col-md-2 {
    width: 16.66667%; }
  .col-md-1 {
    width: 8.33333%; } }

@media (min-width: 768px) and (max-width: 991px) {
  .col-md-break {
    width: 100%; } }

@media (min-width: 992px) {
  .col-lg-12 {
    width: 100%; }
  .col-lg-11 {
    width: 91.66667%; }
  .col-lg-10 {
    width: 83.33333%; }
  .col-lg-9 {
    width: 75%; }
  .col-lg-8 {
    width: 66.66667%; }
  .col-lg-7 {
    width: 58.33333%; }
  .col-lg-6 {
    width: 50%; }
  .col-lg-5 {
    width: 41.66667%; }
  .col-lg-4 {
    width: 33.33333%; }
  .col-lg-3 {
    width: 25%; }
  .col-lg-2 {
    width: 16.66667%; }
  .col-lg-1 {
    width: 8.33333%; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .col-lg-break {
    width: 100%; } }

@media (min-width: 1200px) {
  .col-xl-break {
    width: 100%; }
  .col-xl-12 {
    width: 100%; }
  .col-xl-11 {
    width: 91.66667%; }
  .col-xl-10 {
    width: 83.33333%; }
  .col-xl-9 {
    width: 75%; }
  .col-xl-8 {
    width: 66.66667%; }
  .col-xl-7 {
    width: 58.33333%; }
  .col-xl-6 {
    width: 50%; }
  .col-xl-5 {
    width: 41.66667%; }
  .col-xl-4 {
    width: 33.33333%; }
  .col-xl-3 {
    width: 25%; }
  .col-xl-2 {
    width: 16.66667%; }
  .col-xl-1 {
    width: 8.33333%; } }

@media (min-width: 992px) {
  .block.content .main {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .block.content .sidebar {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

@media (min-width: 992px) {
  .block.content.full_width .main {
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .block.content.block_menu .sidebar {
    flex: 0 0 25%;
    max-width: 25%; }
  .block.content.block_menu .main {
    flex: 0 0 75%;
    max-width: 75%; } }

.block.content.block_welcome .row {
  justify-content: center; }

.block.content.block_welcome .sidebar {
  padding-bottom: 20px; }

@media (min-width: 768px) and (max-width: 991px) {
  .block.content.block_welcome {
    overflow: hidden; }
    .block.content.block_welcome .row {
      display: block;
      flex-wrap: nowrap; }
    .block.content.block_welcome .main {
      order: 2; }
    .block.content.block_welcome .sidebar {
      order: 1;
      float: right;
      width: 45%;
      margin: 0 0 15px 15px; } }

@media (min-width: 992px) {
  .block.content.block_welcome .row {
    align-items: center; }
  .block.content.block_welcome .main {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .block.content.block_welcome .sidebar {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
    padding-bottom: 0; } }

@media (min-width: 1200px) {
  .block.content.block_welcome .main {
    flex: 0 0 50%;
    max-width: 50%; } }

@media (min-width: 992px) {
  .block.content.flip .wrap:nth-child(even) {
    order: 1; }
  .block.content.flip .wrap:nth-child(odd) {
    order: 2; } }

@media (min-width: 768px) {
  .site_info .info_logo {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .site_info .info_copyright {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

body {
  min-width: 360px;
  /*	background: linear-gradient(
		135deg,
		darken(#A06135, 17%),
		lighten(#A06135, 10%) 80%
	);*/
  /*	@include media-breakpoint-up(md) {

		height: 100%;
		background: url("/_/images/layout/logo-mark.png"),
					url("/_/images/layout/body.png");
		background-repeat: no-repeat, repeat;
		background-size: 300px, auto;
		background-position: -50px 30px, left top;
		padding-bottom: 143px;

		.site_info {
			position: absolute;
			bottom: 0;
			width: 100%;
		}

	}*/ }
  @media (min-width: 992px) {
    body {
      background-size: 350px, auto;
      background-position: -55px -25px, left top; } }

#directions,
#hours {
  outline: none !important;
  box-shadow: none !important; }

html {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.68em;
  -webkit-text-size-adjust: 100%; }
  @media (min-width: 768px) {
    html {
      font-size: 17px;
      font-size: 1.0625rem; } }

body {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

h4, .h4, h3, .h3, h2, .h2, .block-title, h1, .h1 {
  margin: 30px 0 15px;
  font-family: "Open Sans Condensed", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1em;
  letter-spacing: -0.0375em;
  text-transform: uppercase; }
  h4 small, .h4 small, h3 small, .h3 small, h2 small, .h2 small, .block-title small, h1 small, .h1 small {
    display: block;
    font-size: 60%;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 600;
    line-height: 1em;
    letter-spacing: -0.0225em;
    text-transform: none; }

h5, .h5,
h6, .h6 {
  margin: 30px 0 15px;
  font-size: 17px;
  font-size: 1.0625rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700; }
  @media (min-width: 992px) {
    h5, .h5,
    h6, .h6 {
      font-size: 18px;
      font-size: 1.125rem; } }

h4, .h4 {
  font-size: 20px;
  font-size: 1.25rem; }
  @media (min-width: 992px) {
    h4, .h4 {
      font-size: 22px;
      font-size: 1.375rem; } }

h3, .h3 {
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: 700; }
  @media (min-width: 992px) {
    h3, .h3 {
      font-size: 28px;
      font-size: 1.75rem; } }

h2, .h2, .block-title {
  font-size: 28px;
  font-size: 1.75rem; }
  @media (min-width: 992px) {
    h2, .h2, .block-title {
      font-size: 32px;
      font-size: 2rem; } }

h1, .h1 {
  font-size: 36px;
  font-size: 2.25rem;
  font-weight: 700; }
  @media (min-width: 992px) {
    h1, .h1 {
      font-size: 46px;
      font-size: 2.875rem; } }

p {
  margin: 0 0 20px; }
  p.lead {
    font-size: 125%;
    line-height: 1.53em; }

ul, ol {
  margin: 0;
  padding: 0; }

b, strong {
  font-weight: 600; }

address {
  margin-bottom: 1em;
  font-style: normal; }

blockquote {
  margin: 0 0 1.5em;
  padding: 1em;
  font-size: 112.5%;
  line-height: 1.425em;
  border-left: 0.5rem solid; }
  blockquote > * {
    margin-bottom: 0.5em; }
  blockquote > *:last-child {
    margin-bottom: 0; }
  blockquote.bq--text {
    border-color: #333; }
  blockquote.bq--primary {
    border-color: #ab0f14; }
  blockquote.bq--secondary {
    border-color: #f99809; }
  blockquote.bq--highlight {
    border-color: #000; }
  blockquote.bq--accent {
    border-color: #786a4b; }
  blockquote.bq--success {
    border-color: #28a745; }
  blockquote.bq--danger {
    border-color: #dc3545; }
  blockquote.bq--warning {
    border-color: #d39e00; }
  blockquote.bq--info {
    border-color: #0182C8; }
  blockquote.review {
    padding: 0;
    margin: 0 0 1em;
    font-size: 1rem;
    border: none; }

.bq--title {
  font-weight: 800;
  letter-spacing: -0.0275em;
  text-transform: uppercase; }
  .bq--text .bq--title {
    color: #333; }
  .bq--primary .bq--title {
    color: #ab0f14; }
  .bq--secondary .bq--title {
    color: #f99809; }
  .bq--highlight .bq--title {
    color: #000; }
  .bq--accent .bq--title {
    color: #786a4b; }
  .bq--success .bq--title {
    color: #28a745; }
  .bq--danger .bq--title {
    color: #dc3545; }
  .bq--warning .bq--title {
    color: #d39e00; }
  .bq--info .bq--title {
    color: #0182C8; }

blockquote.bq-alt {
  margin-right: 5em;
  padding: 1.5em;
  border-radius: 6px;
  border: 4px solid #333;
  background-color: #fff; }
  blockquote.bq-alt.bq--primary {
    border-color: #ab0f14; }
  blockquote.bq-alt.bq--secondary {
    border-color: #f99809; }
  blockquote.bq-alt.bq--highlight {
    border-color: #000; }
  blockquote.bq-alt.bq--accent {
    border-color: #786a4b; }
  blockquote.bq-alt.bq--success {
    border-color: #28a745; }
  blockquote.bq-alt.bq--danger {
    border-color: #dc3545; }
  blockquote.bq-alt.bq--warning {
    border-color: #ffc107; }
  blockquote.bq-alt.bq--info {
    border-color: #0182C8; }

hr {
  display: block;
  width: 100%;
  height: 1px;
  margin: 2em auto;
  border: none;
  background-color: #bbb; }
  hr.hr-inset {
    margin: 2em auto;
    border: none;
    background-color: #bbb;
    box-shadow: 0 1px 0 0 #fff; }
  .content_text hr {
    margin: 1em 0 !important; }

a {
  font-style: normal;
  text-decoration: underline;
  color: #ab0f14; }
  a:hover, a:focus {
    color: #da1319;
    text-decoration: none; }
  a:focus {
    outline: 1px solid #fccc85; }

a.img-link {
  text-decoration: none; }

.phone {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  white-space: nowrap;
  cursor: default;
  color: #333; }
  .phone:hover, .phone:active, .phone:focus {
    text-decoration: none;
    color: #333;
    outline: none;
    cursor: default; }

.phone.mask {
  color: #333; }
  .phone.mask:hover {
    outline: none;
    color: #333; }

.list {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em; }
  .list li:not(:last-child) {
    margin-bottom: 0.25em; }

.list-inline, .list_icon, .list-hours, .nav, .list-unstyled {
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }

.list-inline li {
  display: inline-block; }

.list_icon li {
  padding-left: 22px;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: left center; }

.list_icon.list-inline li:not(:last-child) {
  margin-right: 15px; }

.icon_check-primary li {
  background-image: url("/_/images/icons/cm_primary.png"); }

.icon_check-secondary li {
  background-image: url("/_/images/icons/cm_secondary.png"); }

.icon_check-success li {
  background-image: url("/_/images/icons/cm_success.png"); }

[class*="list-block"] {
  margin: 0 0 1em;
  padding: 0 0 0 1.2em;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;
  list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7); }
  [class*="list-block"] li:not(:last-child) {
    margin-bottom: 0.25em; }
  [class*="list-block"] li {
    display: inline-block; }
  [class*="list-block"] li {
    -webkit-transform: skew(-15deg);
    -moz-transform: skew(-15deg);
    -o-transform: skew(-15deg);
    transform: skew(-15deg);
    margin-bottom: 0.5em !important;
    padding: 3px 10px;
    font-weight: 600;
    letter-spacing: 0.025em;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    background-color: #333;
    color: #fff; }
  [class*="list-block"] li:not(:last-child) {
    margin-right: 0.25em; }

.list-block--primary li {
  background-color: #9d1d21; }

.list-block--secondary li {
  background-color: #f99809; }

.list-block--highlight li {
  background-color: #000; }

.list-block--accent li {
  background-color: #786a4b; }

.list-block--success li {
  background-color: #28a745; }

.list-block--danger li {
  background-color: #dc3545; }

.list-block--warning li {
  background-color: #ffc107; }

.list-block--info li {
  background-color: #0182C8; }

img {
  display: block;
  max-width: 100%;
  height: auto;
  border-style: none; }

.img-responsive {
  width: 100%; }

.img-main {
  margin: 0 0 15px;
  max-width: 380px; }

.img-coupon {
  max-width: 640px; }

.img-about {
  width: 150px; }

.img-auto {
  width: auto; }

.img--badge {
  float: right;
  margin: 0 0 10px 10px; }
  .img--badge.badge-cccc {
    max-width: 150px; }

.img-inline {
  display: inline; }

.img-thumbnail {
  padding: 4px;
  border: 1px solid #bbb;
  background-color: #fff; }

@media (min-width: 768px) {
  .img--right {
    clear: both;
    float: right;
    width: 42.5%;
    margin: 0 0 15px 15px; }
    .img--right.img-captioned span {
      text-align: right; } }

@media (min-width: 768px) {
  .img--left {
    clear: both;
    float: left;
    width: 42.5%;
    margin: 0 20px 15px 0; } }

.img--wide {
  margin: 0 0 15px;
  width: 100%;
  max-width: none; }

.img--auto {
  max-width: auto; }
  @media (min-width: 576px) {
    .img--auto {
      width: auto;
      height: auto; } }

.img-captioned {
  margin-bottom: 15px; }
  .img-captioned img {
    width: 100%;
    margin-bottom: 0; }
  .img-captioned span {
    display: block;
    max-width: 360px;
    margin: 5px 10px 0;
    font-size: 14px;
    font-size: 0.875rem;
    font-style: italic;
    line-height: 1.325em;
    color: #4d4d4d; }

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 15px;
  line-height: 1em;
  background-color: #fff;
  border-spacing: 0;
  border-collapse: collapse;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }
  .table * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .table th,
  .table td {
    padding: 10px;
    vertical-align: top;
    border-top: 1px solid #ccc; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #ccc; }
  .table tbody + tbody {
    border-top: 2px solid #ccc; }

.table-bordered {
  border: 1px solid #ccc; }
  .table-bordered th, .table-bordered td {
    border: 1px solid #ccc; }
  .table-bordered thead th, .table-bordered thead td {
    border-bottom-width: 2px; }

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f3f3f3; }

.table-hover tbody tr:hover {
  background-color: #e5e5e5;
  cursor: pointer; }

@media (max-width: 767px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive > .table-bordered {
      border: 0; } }

.embed_responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed_responsive.embed_responsive_16by9 {
    padding-bottom: 56.25%; }
  .embed_responsive.embed_responsive_4by3 {
    padding-bottom: 75%; }
  .embed_responsive .embed_responsive_item, .embed_responsive embed, .embed_responsive iframe, .embed_responsive object, .embed_responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.address {
  margin: 0 0 15px;
  line-height: 1em; }

.alert {
  background-color: #ddd;
  color: #333;
  padding: 18px 15px;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .alert .alert_body {
    width: 93%;
    order: 1; }
  .alert .alert_button {
    width: 7%;
    order: 2; }
    .alert .alert_button .button_close {
      width: 26px;
      height: 26px;
      float: right; }
  .alert.--has-conversion {
    padding: 10px 15px; }

.alert-primary {
  background-color: #ab0f14;
  color: #333; }

.alert-secondary {
  background-color: #f99809;
  color: #333; }

.alert-highlight {
  background-color: #000;
  color: #fff; }

.alert-accent {
  background-color: #786a4b;
  color: #333; }

.alert-success {
  background-color: #28a745;
  color: #333; }

.alert-danger {
  background-color: #dc3545;
  color: #333; }

.alert-info {
  background-color: #0182C8;
  color: #333; }

.alert-warning {
  background-color: #ffc107;
  color: #333; }

.alert-link {
  background-color: #da1319;
  color: #333; }

.alert-title {
  margin-top: 0;
  margin-right: 34px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  letter-spacing: -0.0325em; }

.btn {
  display: inline-block;
  padding: 12px 15px;
  font-family: "Open Sans Condensed", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px;
  border: 1px solid;
  -webkit-transform: scale(0.95);
  -moz-transform: scale(0.95);
  -o-transform: scale(0.95);
  transform: scale(0.95);
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms; }
  .btn small {
    display: block;
    line-height: 1em; }
  .btn:hover {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms; }
  .btn:focus {
    outline: 4px solid rgba(0, 0, 0, 0.25); }

.btn-text {
  border-color: #1a1a1a;
  background-color: #333;
  color: #fff; }
  .btn-text:hover, .btn-text:focus {
    background-color: #535353;
    color: #fff; }

.btn-link {
  border-color: #ab0f14;
  background-color: #da1319;
  color: #fff; }
  .btn-link:hover, .btn-link:focus {
    background-color: #ee3f44;
    color: #fff; }

.btn-primary {
  border-color: #7c0b0f;
  background-color: #ab0f14;
  color: #fff; }
  .btn-primary:hover, .btn-primary:focus {
    background-color: #e6141b;
    color: #fff; }

.btn-secondary {
  border-color: #ca7a05;
  background-color: #f99809;
  color: #fff; }
  .btn-secondary:hover, .btn-secondary:focus {
    background-color: #fbb247;
    color: #fff; }

.btn-highlight {
  border-color: black;
  background-color: #000;
  color: #fff; }
  .btn-highlight:hover, .btn-highlight:focus {
    background-color: #202020;
    color: #fff; }

.btn-accent {
  border-color: #594e37;
  background-color: #786a4b;
  color: #fff; }
  .btn-accent:hover, .btn-accent:focus {
    background-color: #907f5a;
    color: #fff; }

.btn-success {
  border-color: #1e7e34;
  background-color: #28a745;
  color: #fff; }
  .btn-success:hover, .btn-success:focus {
    background-color: #3ed160;
    color: #fff; }

.btn-danger {
  border-color: #bd2130;
  background-color: #dc3545;
  color: #fff; }
  .btn-danger:hover, .btn-danger:focus {
    background-color: #e56b77;
    color: #fff; }

.btn-info {
  border-color: #016195;
  background-color: #0182C8;
  color: #fff; }
  .btn-info:hover, .btn-info:focus {
    background-color: #0ba8fe;
    color: #fff; }

.btn-review {
  border-color: #dc6502;
  background-color: #fd7e14;
  color: #fff; }
  .btn-review:hover, .btn-review:focus {
    background-color: #fea153;
    color: #fff; }

.btn-warning {
  border-color: #d39e00;
  background-color: #ffc107;
  color: #fff; }
  .btn-warning:hover, .btn-warning:focus {
    background-color: #ffd147;
    color: #fff; }

.btn-orange {
  border-color: #dc6502;
  background-color: #fd7e14;
  color: #fff; }
  .btn-orange:hover, .btn-orange:focus {
    background-color: #fea153;
    color: #fff; }

.btn-block {
  display: block;
  text-align: center; }

.btn-icon {
  vertical-align: bottom; }
  .btn-icon img {
    display: inline-block;
    width: 22px;
    margin-right: 0.75em;
    vertical-align: middle; }
  .btn-icon.btn--lg img {
    width: 28px; }
  .btn-icon.btn--sm img {
    width: 18px; }

.btn--sm {
  padding: 8px 10px;
  font-size: 14px;
  font-size: 0.875rem; }

.btn--md {
  padding: 12px 15px;
  font-size: 16px;
  font-size: 1rem; }

.btn--lg {
  padding: 15px 25px;
  font-size: 18px;
  font-size: 1.125rem; }

.btn--wide {
  display: block;
  width: 100%; }

.button_close {
  padding: 5px;
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 1em;
  text-align: center;
  border-radius: 3px;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  background-image: url("/_/images/icons/close.png");
  background-size: 14px 14px;
  background-position: center center;
  background-repeat: no-repeat;
  color: #fff;
  cursor: pointer; }
  .button_close:hover {
    border-color: #dc3545;
    background-color: #e4606d; }

[class*="btn-icon"] {
  padding: 15px 15px 15px 45px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1em;
  text-transform: uppercase;
  background-position: 12px 9px;
  background-repeat: no-repeat;
  background-size: 24px; }

.btn-icon--calendar {
  background-image: url("/_/images/icons/btn-calendar.png"); }
  .btn-icon--calendar:hover {
    background-image: url("/_/images/icons/btn-calendar-hover.png"); }

.btn-icon--map {
  background-image: url("/_/images/icons/btn-map.png"); }
  .btn-icon--map:hover {
    background-image: url("/_/images/icons/btn-map-hover.png"); }

.btn-icon--review {
  background-image: url("/_/images/icons/btn-review.png"); }
  .btn-icon--review:hover {
    background-image: url("/_/images/icons/btn-review-hover.png"); }

.button_mobile {
  float: left;
  overflow: hidden; }

.mobile_toggle {
  width: 44px;
  height: 36px;
  padding: 9px 8px 8px 10px;
  background-color: #f99809;
  border: none;
  border-radius: 4px;
  color: #fff; }
  .mobile_toggle:hover, .mobile_toggle:focus {
    outline: none;
    background-color: #faad3b;
    cursor: pointer; }

.button-bars {
  display: inline-block;
  height: 100%;
  width: 24px; }

.icon-bar {
  -webkit-transition: transform ease-in-out 300ms;
  -moz-transition: transform ease-in-out 300ms;
  -ms-transition: transform ease-in-out 300ms;
  -o-transition: transform ease-in-out 300ms;
  transition: transform ease-in-out 300ms;
  display: block;
  width: 100%;
  height: 3px;
  border-radius: 4px;
  background-color: #fff; }
  .icon-bar:nth-child(2) {
    margin: 4px 0; }

.canvas-slid .icon-bar:nth-child(2) {
  visibility: hidden; }

.canvas-slid .icon-bar:nth-child(1), .canvas-slid .icon-bar:nth-child(3) {
  -webkit-transition: transform ease-in-out 150ms;
  -moz-transition: transform ease-in-out 150ms;
  -ms-transition: transform ease-in-out 150ms;
  -o-transition: transform ease-in-out 150ms;
  transition: transform ease-in-out 150ms; }

.canvas-slid .icon-bar:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 7px; }

.canvas-slid .icon-bar:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -14px; }

.button_conversion {
  display: inline-block;
  padding: 15px 20px 15px 55px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 800;
  line-height: 1em;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 3px;
  border: 1px solid #bd2130;
  background-color: #dc3545;
  background-image: url("/_/images/icons/truck.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 55px auto;
  color: #fff;
  box-shadow: 0 22px 9px -20px rgba(0, 0, 0, 0.7);
  transform: scale(0.925);
  transition: none;
  transition-timing-function: linear;
  transition-duration: 300ms, 300ms, 200ms;
  transition-property: background-position, padding, transform, box-shadow;
  transition-delay: 0ms, 0ms, 0ms, 0ms; }
  .button_conversion:link, .button_conversion:visited, .button_conversion:active {
    text-decoration: none;
    border: 1px solid #bd2130;
    background-color: #dc3545;
    color: #fff; }
  .button_conversion:hover, .button_conversion:focus {
    padding: 15px 55px 15px 20px;
    background-color: #e04b59;
    background-position: right center;
    box-shadow: 0 22px 9px -20px transparent;
    color: #fff;
    transform: scale(1);
    transition-duration: 600ms, 300ms, 100ms, 300ms;
    transition-delay: 200ms, 100ms, 0ms, 300ms; }
  @media (min-width: 992px) {
    .button_conversion {
      font-size: 1.3rem; } }
  @media (min-width: 1200px) {
    .button_conversion {
      font-size: 1.5rem; } }

.call {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1em; }
  .call .phone {
    display: inline-block;
    font-size: 22px;
    font-size: 1.375rem;
    font-weight: 800;
    line-height: 1em;
    letter-spacing: -0.04em; }
    .call .phone.company-phone {
      color: #fff; }
    .call .phone.contact-phone {
      color: #f99809; }
  .call .contact-note {
    display: block;
    font-weight: 600; }

.coverage {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }
  .coverage .coverage_title {
    margin-top: 0;
    font-size: 20px;
    font-size: 1.25rem; }
  .coverage .coverage_county,
  .coverage .coverage_city {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.325em; }
  .coverage.block {
    padding: 30px 15px;
    background-color: #eee; }
    .coverage.block .wrap > *:last-child {
      margin-bottom: 0; }
    @media (min-width: 992px) {
      .coverage.block .wrap {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
        text-align: center; } }
    @media (min-width: 1200px) {
      .coverage.block .wrap {
        flex: 0 0 75%;
        max-width: 75%; } }

.faq_item {
  margin-bottom: 1em;
  border: 1px solid #ccc;
  background-color: #fff;
  counter-increment: section; }
  .faq_item:nth-child(even) .faq_title {
    background-color: #f0f0f0; }
  .faq_item .faq_title h2 {
    margin: 0;
    padding: 0.5em;
    font-size: 1.2rem;
    letter-spacing: 0; }
    .faq_item .faq_title h2:hover {
      color: #da1319;
      cursor: pointer; }
    .faq_item .faq_title h2:before {
      display: inline-block;
      width: 42px;
      content: counter(section) ". "; }
  .faq_item.open .faq_title h2 {
    background-color: #ab0f14;
    color: #fff; }
  .faq_item .faq_body {
    padding: 1em 1em 0.5em; }
    .faq_item .faq_body p, .faq_item .faq_body ul, .faq_item .faq_body ol {
      margin-bottom: 0.5em;
      line-height: 1.4em; }
    .faq_item .faq_body ul li:not(:last-child), .faq_item .faq_body ol li:not(:last-child) {
      margin-bottom: 0.25em; }

.form {
  font-size: 16px;
  font-size: 1rem;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em; }

.form_section {
  overflow: hidden;
  margin: 0 0 20px;
  padding: 15px 15px 7px;
  border: 1px solid #ccc; }
  .form_section legend {
    padding: 0 45px 0 10px;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: -0.0475em; }

.form-title {
  margin: 0 0 30px; }

.form_group {
  margin: 0 0 8px;
  padding: 0; }
  .form_group.form_hp {
    display: none; }
  .form_group.form_clear {
    clear: both; }

label {
  display: block;
  margin: 0 0 1px 2px;
  font-size: 0.9rem;
  font-weight: 600;
  line-height: 1em;
  letter-spacing: -0.0275em; }

.form_control {
  display: block;
  width: 100%;
  margin: 0 0 5px;
  padding: 9px 7px;
  line-height: 1em;
  border-radius: 2px;
  border: 1px solid #ddd;
  background-color: #fff;
  outline: none; }
  .form_control:focus {
    border-color: #ffc107;
    background-color: #fff4d3; }

textarea.form_control {
  min-height: 150px;
  line-height: 1.275em; }

.form_checkbox {
  margin: 0 0 8px;
  vertical-align: top;
  line-height: 1em; }
  .form_checkbox label {
    display: block;
    min-height: 24px;
    padding: 6px 0 7px 32px;
    font-size: 16px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1em;
    cursor: pointer; }
  .form_checkbox .checkbox {
    display: block;
    margin: -3px 5px 0 -29px;
    float: left;
    width: 23px;
    height: 23px; }

.form_error .form_control {
  border-radius: 2px 2px 0 0;
  border-color: #dc3545 !important; }

.error-message {
  margin: -5px 0 0;
  padding: 6px 4px 4px;
  font-size: 0.7em;
  font-weight: 800;
  line-height: 1em;
  text-transform: uppercase;
  background-color: #dc3545;
  color: #fff; }

.form_spam {
  position: relative;
  height: 70px; }
  .form_spam .g-recaptcha {
    position: absolute;
    top: -5px;
    left: -18px;
    -webkit-transform: scale(0.88);
    -moz-transform: scale(0.88);
    -o-transform: scale(0.88);
    transform: scale(0.88); }

@media (min-width: 768px) and (max-width: 991px) {
  .form_quick .form_left,
  .form_quick .form_right {
    float: left;
    width: 49%; }
  .form_quick .form_left {
    margin-right: 2%; }
  .form_quick .form_clear {
    clear: both; } }

@media (min-width: 768px) {
  .page_contact .form_quick .form_left,
  .page_contact .form_quick .form_right {
    float: left;
    width: 49%; }
  .page_contact .form_quick .form_left {
    margin-right: 2%; }
  .page_contact .form_quick .form_clear {
    clear: both; } }

.sidebar .form_quick {
  padding: 15px;
  border: 1px solid #ccc;
  background-color: #f0f0f0; }
  .sidebar .form_quick .form-title {
    margin-bottom: 15px; }

.gallery {
  overflow: hidden;
  margin-bottom: 1em; }

.gallery_sidebar img {
  margin-bottom: 15px; }

.gallery_lightbox img {
  padding: 4px;
  border: 1px solid #ccc;
  background-color: #fff; }

.gallery_lightbox > a {
  margin-bottom: 1%; }

.gallery_menu {
  /*&:hover {
            small {
                @include transition(all ease-in-out 200ms);
                opacity: 1;
            } 
        }
        @include media-breakpoint-up(md) {
            small { display: inline-block; }
        }*/ }
  .gallery_menu a:not(.btn) {
    -webkit-transform: scale(0.92);
    -moz-transform: scale(0.92);
    -o-transform: scale(0.92);
    transform: scale(0.92);
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    display: inline-block;
    width: 60px;
    height: 60px;
    margin: 0 0 5px;
    vertical-align: middle; }
    .gallery_menu a:not(.btn) img {
      -webkit-transition: all ease-in-out 200ms;
      -moz-transition: all ease-in-out 200ms;
      -ms-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms;
      padding: 2px; }
    .gallery_menu a:not(.btn):hover {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all ease-in-out 200ms;
      -moz-transition: all ease-in-out 200ms;
      -ms-transition: all ease-in-out 200ms;
      -o-transition: all ease-in-out 200ms;
      transition: all ease-in-out 200ms; }
      .gallery_menu a:not(.btn):hover img {
        -webkit-transition: all ease-in-out 200ms;
        -moz-transition: all ease-in-out 200ms;
        -ms-transition: all ease-in-out 200ms;
        -o-transition: all ease-in-out 200ms;
        transition: all ease-in-out 200ms;
        background-color: #786a4b;
        border-color: #786a4b; }
  .gallery_menu small {
    display: block; }

@media (max-width: 575px) {
  .g2-xs > img, .g2-xs > a, .g2-xs > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-xs > img:nth-child(odd), .g2-xs > a:nth-child(odd), .g2-xs > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-xs > img:nth-child(even), .g2-xs > a:nth-child(even), .g2-xs > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g2-sm > img, .g2-sm > a, .g2-sm > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-sm > img:nth-child(odd), .g2-sm > a:nth-child(odd), .g2-sm > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-sm > img:nth-child(even), .g2-sm > a:nth-child(even), .g2-sm > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g2-md > img, .g2-md > a, .g2-md > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-md > img:nth-child(odd), .g2-md > a:nth-child(odd), .g2-md > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-md > img:nth-child(even), .g2-md > a:nth-child(even), .g2-md > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g2-lg > img, .g2-lg > a, .g2-lg > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-lg > img:nth-child(odd), .g2-lg > a:nth-child(odd), .g2-lg > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-lg > img:nth-child(even), .g2-lg > a:nth-child(even), .g2-lg > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 1200px) {
  .g2-xl > img, .g2-xl > a, .g2-xl > .img-captioned {
    display: block;
    float: left;
    width: 49.5%; }
    .g2-xl > img:nth-child(odd), .g2-xl > a:nth-child(odd), .g2-xl > .img-captioned:nth-child(odd) {
      clear: both; }
    .g2-xl > img:nth-child(even), .g2-xl > a:nth-child(even), .g2-xl > .img-captioned:nth-child(even) {
      float: right; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g3-sm > img, .g3-sm > a, .g3-sm > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-sm > img:nth-child(3n-2), .g3-sm > a:nth-child(3n-2), .g3-sm > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-sm > img:nth-child(3n), .g3-sm > a:nth-child(3n), .g3-sm > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g3-md > img, .g3-md > a, .g3-md > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-md > img:nth-child(3n-2), .g3-md > a:nth-child(3n-2), .g3-md > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-md > img:nth-child(3n), .g3-md > a:nth-child(3n), .g3-md > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g3-lg > img, .g3-lg > a, .g3-lg > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-lg > img:nth-child(3n-2), .g3-lg > a:nth-child(3n-2), .g3-lg > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-lg > img:nth-child(3n), .g3-lg > a:nth-child(3n), .g3-lg > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g3-xl > img, .g3-xl > a, .g3-xl > .img-captioned {
    display: block;
    float: left;
    width: 32.66667%;
    margin-right: 1%; }
    .g3-xl > img:nth-child(3n-2), .g3-xl > a:nth-child(3n-2), .g3-xl > .img-captioned:nth-child(3n-2) {
      clear: both; }
    .g3-xl > img:nth-child(3n), .g3-xl > a:nth-child(3n), .g3-xl > .img-captioned:nth-child(3n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 576px) and (max-width: 767px) {
  .g4-sm > img, .g4-sm > a, .g4-sm > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-sm > img:nth-child(4n-3), .g4-sm > a:nth-child(4n-3), .g4-sm > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-sm > img:nth-child(4n), .g4-sm > a:nth-child(4n), .g4-sm > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 768px) and (max-width: 991px) {
  .g4-md > img, .g4-md > a, .g4-md > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-md > img:nth-child(4n-3), .g4-md > a:nth-child(4n-3), .g4-md > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-md > img:nth-child(4n), .g4-md > a:nth-child(4n), .g4-md > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .g4-lg > img, .g4-lg > a, .g4-lg > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-lg > img:nth-child(4n-3), .g4-lg > a:nth-child(4n-3), .g4-lg > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-lg > img:nth-child(4n), .g4-lg > a:nth-child(4n), .g4-lg > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g4-xl > img, .g4-xl > a, .g4-xl > .img-captioned {
    display: block;
    float: left;
    width: 24.25%;
    margin-right: 1%; }
    .g4-xl > img:nth-child(4n-3), .g4-xl > a:nth-child(4n-3), .g4-xl > .img-captioned:nth-child(4n-3) {
      clear: both; }
    .g4-xl > img:nth-child(4n), .g4-xl > a:nth-child(4n), .g4-xl > .img-captioned:nth-child(4n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 992px) {
  .g5-lg > img, .g5-lg > a, .g5-lg > .img-captioned {
    display: block;
    float: left;
    width: 19.2%;
    margin-right: 1%; }
    .g5-lg > img:nth-child(5n-4), .g5-lg > a:nth-child(5n-4), .g5-lg > .img-captioned:nth-child(5n-4) {
      clear: both; }
    .g5-lg > img:nth-child(5n), .g5-lg > a:nth-child(5n), .g5-lg > .img-captioned:nth-child(5n) {
      float: right;
      margin-right: 0; } }

@media (min-width: 1200px) {
  .g5-xl > img, .g5-xl > a, .g5-xl > .img-captioned {
    display: block;
    float: left;
    width: 19.2%;
    margin-right: 1%; }
    .g5-xl > img:nth-child(5n-4), .g5-xl > a:nth-child(5n-4), .g5-xl > .img-captioned:nth-child(5n-4) {
      clear: both; }
    .g5-xl > img:nth-child(5n), .g5-xl > a:nth-child(5n), .g5-xl > .img-captioned:nth-child(5n) {
      float: right;
      margin-right: 0; } }

.hours {
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1em; }

.contact-hours .hours-now {
  position: relative;
  display: inline-block;
  text-decoration: none;
  color: #333;
  outline: none !important; }
  @media (min-width: 992px) {
    .contact-hours .hours-now {
      color: #fff; } }

.contact-hours .hours-now-wrap {
  display: inline-block;
  padding: 10px 15px 10px 0;
  background: url("/_/images/icons/caret-hours.png") right center no-repeat;
  background-size: 12px; }
  @media (min-width: 992px) {
    .contact-hours .hours-now-wrap {
      background-image: url("/_/images/icons/caret.png"); } }

.contact-hours .list-hours {
  display: none;
  width: auto;
  margin: 0 auto 15px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  color: #333; }
  @media (min-width: 768px) {
    .contact-hours .list-hours {
      position: absolute;
      z-index: 1;
      margin-top: 3px; } }

.list-hours {
  text-align: left; }
  .list-hours li {
    margin: 0;
    padding: 7px 10px;
    white-space: nowrap; }
    .list-hours li:nth-child(even) {
      background-color: #eee; }
    .list-hours li.active {
      color: #ab0f14; }
    .list-hours li:not(:last-child) {
      border-bottom: 1px dotted #ccc; }
  .list-hours b {
    display: inline-block;
    width: 100px; }

.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99000;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.9;
  display: none; }

.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 99500;
  text-align: center;
  line-height: 0;
  font-weight: 400; }
  @media (min-width: 768px) {
    .lightbox {
      margin-top: 75px; } }

.lightbox .lb-image {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto; }

.lightbox a img {
  border: none; }

.lb-outerContainer {
  position: relative;
  padding: 3px;
  overflow: hidden;
  background-color: white;
  *zoom: 1;
  margin: 0 auto; }

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0; }

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url("/_/images/layout/loading.gif") no-repeat; }

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10; }

.lb-container > .nav {
  left: 0; }

.lb-nav a {
  outline: none;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="); }

.lb-prev, .lb-next {
  height: 100%;
  cursor: pointer;
  display: block; }

.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  background: url("/_/images/icons/lb_prev.png") left 48% no-repeat;
  background-size: 36px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  background: url("/_/images/icons/lb_next.png") right 48% no-repeat;
  background-size: 36px;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -ms-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s; }

.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.lb-dataContainer {
  margin: 0 auto;
  padding: 7px 0;
  *zoom: 1;
  width: 100%;
  border-top: 3px solid #fff;
  background-color: transparent;
  color: #fff; }

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both; }

.lb-data {
  padding: 0 4px; }

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em; }

.lb-data .lb-caption {
  display: inline-block;
  padding: 6px 5px 0;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1em; }

.lb-data .lb-number {
  display: none !important;
  visibility: hidden !important; }

.lb-data .lb-close {
  display: block;
  float: right;
  margin-right: 5px;
  width: 30px;
  height: 30px;
  background: url("/_/images/icons/close.png") center center no-repeat;
  background-size: 20px 20px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -ms-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s; }

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1; }

.logo-banner {
  display: block;
  max-width: 270px;
  width: 100%;
  margin: 0 auto 15px;
  line-height: 0em; }

.logo-footer {
  display: block;
  max-width: 200px;
  margin: 0 auto; }

.directions_map {
  border: 1px solid #ccc; }
  @media (min-width: 992px) {
    .directions_map .contact_map {
      padding-bottom: 125%; } }
  @media (min-width: 1200px) {
    .directions_map .contact_map {
      padding-bottom: 90%; } }

.contact_map {
  border: 5px solid #fff; }

.g_map {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .g_map:hover {
    cursor: pointer; }
    .g_map:hover p {
      opacity: 1; }
  .g_map p {
    position: absolute;
    top: 45%;
    width: 100%;
    padding: 0.5em;
    font-size: 20px;
    font-size: 1.25rem;
    font-family: "Open Sans Condensed", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
    background-color: rgba(249, 152, 9, 0.8);
    color: #fff;
    opacity: 0; }

.about-map {
  background-image: url("/_/images/maps/sm.webp");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat; }
  .about-map .g_map,
  .about-map .embed-responsive-item {
    padding-bottom: 56.25%; }
  @media (min-width: 768px) {
    .about-map {
      background-image: url("/_/images/maps/lg.webp"); } }

.nav-jump {
  margin-bottom: 15px;
  background-color: #fff; }
  .nav-jump li {
    display: inline-block;
    margin-bottom: 8px; }
  @media (min-width: 992px) {
    .nav-jump li {
      display: block; }
      .nav-jump li .btn {
        display: block; }
      .nav-jump li.active .btn {
        border: none;
        background-color: transparent;
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        transform: scale(1); }
        .nav-jump li.active .btn.btn-primary {
          color: #ab0f14; }
        .nav-jump li.active .btn.btn-secondary {
          color: #f99809; } }

.table-menu {
  margin-bottom: 0; }
  .table-menu td {
    border-top: none;
    border-bottom: 1px dotted #ccc;
    padding: 15px;
    line-height: 1.5em; }
  .table-menu thead .mt-title {
    vertical-align: middle; }
  .table-menu .mt-price {
    white-space: nowrap;
    text-align: right; }
  .table-menu .menu-item-price {
    float: none; }
  .table-menu tbody > tr:last-of-type td {
    border-bottom: none; }
  .table-menu.table--sm td {
    padding: 10px; }

.menu_section {
  padding: 15px 0;
  outline: none !important; }
  .menu_section:nth-child(even) .menu-section-title {
    color: #ab0f14; }
  @media (min-width: 992px) {
    .menu_section {
      padding: 45px 0 0; } }

.menu-section-title {
  margin: 0 0 15px;
  padding-bottom: 15px;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #ccc;
  color: #f99809; }

.menu-list .menu-item {
  padding: 15px;
  margin: 0 !important;
  line-height: 1.5em; }

.menu-list .menu-item:nth-child(even) {
  background-color: #eee; }

.menu-list .menu-item:first-child {
  padding-top: 0; }

.menu-list .menu-item:not(:last-child) {
  border-bottom: 1px dotted #ccc; }

.menu-item-title {
  font-size: 18px;
  font-size: 1.125rem; }

.menu-item-price {
  display: block;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 600;
  color: #28a745; }
  @media (min-width: 768px) {
    .menu-item-price {
      display: inline-block;
      float: right; } }

.menu-item-description {
  margin: 0;
  font-size: 15px;
  font-size: 0.9375rem;
  font-style: italic;
  line-height: 1.5em; }
  @media (min-width: 768px) {
    .menu-item-description {
      margin-top: 5px;
      max-width: 540px; } }

.menu-addons {
  margin: 5px 0 0 0;
  padding-top: 5px; }
  .menu-addons li:before {
    font-weight: 800;
    content: "- "; }
  .menu-addons .addon-price {
    font-weight: 600;
    color: #28a745; }
    .menu-addons .addon-price:before {
      display: inline-block;
      padding-right: 5px;
      font-weight: 400;
      color: #333;
      content: "....."; }

.nav {
  margin: 0;
  padding: 0; }

.nav-item {
  box-sizing: border-box;
  list-style-type: none; }
  .nav-item.active .nav-link {
    cursor: default; }
    .nav-item.active .nav-link:hover {
      cursor: default; }
  .nav-item.open .dropdown-nav {
    display: block;
    height: auto; }
  .nav-item.open .caret {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }

.nav-link {
  -webkit-transition: all ease-in-out 450ms;
  -moz-transition: all ease-in-out 450ms;
  -ms-transition: all ease-in-out 450ms;
  -o-transition: all ease-in-out 450ms;
  transition: all ease-in-out 450ms;
  display: block;
  padding: 12px 15px;
  line-height: 1em;
  text-decoration: none; }
  .nav-link:hover, .nav-link:focus {
    -webkit-transition: all ease-in-out 450ms;
    -moz-transition: all ease-in-out 450ms;
    -ms-transition: all ease-in-out 450ms;
    -o-transition: all ease-in-out 450ms;
    transition: all ease-in-out 450ms;
    outline: none; }

.caret {
  display: inline-block;
  position: relative;
  vertical-align: baseline;
  width: 0.7em;
  height: 0.7em;
  background-image: url("/_/images/icons/caret.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center; }

.dropdown-toggle:hover .caret,
.open .dropdown-toggle .caret {
  background-image: url("/_/images/icons/caret.png"); }

.dropdown-nav {
  -webkit-transition: all ease-in-out 300ms;
  -moz-transition: all ease-in-out 300ms;
  -ms-transition: all ease-in-out 300ms;
  -o-transition: all ease-in-out 300ms;
  transition: all ease-in-out 300ms;
  display: none;
  position: relative;
  z-index: 95000;
  margin: 0;
  padding: 1em;
  background-color: #fff; }
  .dropdown-nav .nav-item {
    list-style-image: url(data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7);
    text-align: left; }
  .dropdown-nav .nav-link {
    padding-left: 10px; }
    .dropdown-nav .nav-link:hover, .dropdown-nav .nav-link:focus {
      outline: none; }
  @media (min-width: 768px) {
    .dropdown-nav {
      position: absolute; } }

.banner-nav {
  overflow: hidden;
  margin-top: 15px; }
  .banner-nav .nav-item {
    display: none; }
    .banner-nav .nav-item.nav-menu_breakfast, .banner-nav .nav-item.nav-menu_lunch {
      display: inline-block; }
    .banner-nav .nav-item.nav-menu_lunch {
      margin-left: 10px; }
    .banner-nav .nav-item.active .nav-link {
      background-color: transparent;
      color: #ab0f14; }
  .banner-nav .nav-link {
    padding: 12px 20px;
    font-size: 18px;
    font-size: 1.125rem;
    font-family: "Open Sans Condensed", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 700;
    border-radius: 4px;
    background-color: #000;
    color: #fff; }
    .banner-nav .nav-link:hover {
      background-color: #f99809; }
  @media (min-width: 768px) {
    .banner-nav {
      text-align: center; }
      .banner-nav .nav-item {
        display: inline-block;
        padding: 0 0 0 6px; }
        .banner-nav .nav-item.nav-menu_lunch {
          margin-left: 0; }
      .banner-nav .nav-link {
        padding: 10px 15px; } }
  @media (min-width: 992px) {
    .banner-nav {
      margin-top: 0; } }

.nav_mobile {
  display: none;
  position: fixed;
  z-index: 999999;
  top: 0;
  right: auto;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  width: 80%;
  max-width: 320px;
  height: 100%;
  padding: 45px 15px 0;
  border-right: 3px solid #fff;
  box-shadow: 2px 0 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #ab0f14;
  color: #fff; }
  .nav_mobile.in {
    display: block; }
  @media (min-width: 768px) {
    .nav_mobile {
      display: none !important; } }
  .nav_mobile hr {
    margin: 15px 0;
    background-color: #fff; }
  .nav_mobile .nav > .nav-item:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.35); }
  .nav_mobile .nav > .nav-item:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.5); }
  .nav_mobile .nav > .nav-item.active .nav-link {
    background-color: #fff;
    color: #ab0f14; }
    .nav_mobile .nav > .nav-item.active .nav-link:hover, .nav_mobile .nav > .nav-item.active .nav-link:focus {
      background-color: #fff;
      color: #ab0f14; }
  .nav_mobile .nav > .nav-item.open > .nav-link {
    border-bottom: 1px solid rgba(0, 0, 0, 0.35);
    background-color: #f99809; }
  .nav_mobile .nav > .nav-item.open .dropdown-nav {
    border-top: 1px solid rgba(255, 255, 255, 0.5); }
  .nav_mobile .nav-item > .nav-link {
    padding: 15px;
    font-weight: 600;
    background-color: transparent;
    color: #fff; }
    .nav_mobile .nav-item > .nav-link:hover, .nav_mobile .nav-item > .nav-link:focus {
      outline: none;
      background-color: #f99809;
      color: #fff; }
  .nav_mobile .dropdown-nav {
    background-color: #f99809;
    padding: 0; }
    .nav_mobile .dropdown-nav .nav-item:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.35); }
    .nav_mobile .dropdown-nav .nav-item:not(:first-child) {
      border-top: 1px solid rgba(255, 255, 255, 0.5); }
    .nav_mobile .dropdown-nav .nav-link {
      letter-spacing: -0.0325em; }
      .nav_mobile .dropdown-nav .nav-link:hover {
        background-color: #ca7a05;
        color: #fff; }
  .nav_mobile .mobile-toggle {
    font-size: 1.2em;
    color: #fff;
    text-decoration: none; }
    .nav_mobile .mobile-toggle img {
      display: inline-block;
      width: 0.8em;
      margin-right: 10px; }

.review {
  padding: 0 60px; }
  .review cite {
    display: block;
    margin-top: 1em;
    font-size: 15px;
    font-size: 0.9375rem;
    font-style: normal;
    line-height: 1em;
    text-align: center; }
    .review cite img {
      display: block;
      width: 120px;
      margin: 0 auto 5px; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }

.slick-list:focus {
  outline: none; }

.slick-list.dragging {
  cursor: pointer;
  cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }

.slick-track:before,
.slick-track:after {
  display: table;
  content: ''; }

.slick-track:after {
  clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px; }

.slick-slide img {
  display: block;
  margin: 0; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("/_/images/layout/ajax-loader.gif") center center no-repeat; }

/* Arrows */
.slick-prev,
.slick-next {
  -webkit-transition: all ease-in-out 200ms;
  -moz-transition: all ease-in-out 200ms;
  -ms-transition: all ease-in-out 200ms;
  -o-transition: all ease-in-out 200ms;
  transition: all ease-in-out 200ms;
  z-index: 95000;
  position: absolute;
  display: block;
  height: 100%;
  top: 0;
  bottom: 0;
  width: 55px;
  font-size: 0px;
  line-height: 0px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
  color: transparent;
  padding: 0;
  border: none;
  outline: none;
  opacity: 0; }
  .slick-prev:hover,
  .slick-next:hover {
    -webkit-transition: all ease-in-out 200ms;
    -moz-transition: all ease-in-out 200ms;
    -ms-transition: all ease-in-out 200ms;
    -o-transition: all ease-in-out 200ms;
    transition: all ease-in-out 200ms;
    outline: none;
    opacity: 0.6; }

.slick-prev {
  left: 0;
  background-image: url("/_/images/icons/angle-left.svg"); }

.slick-next {
  right: 0;
  background-image: url("/_/images/icons/angle-right.svg"); }

.slideshow:hover .slick-prev,
.slideshow:hover .slick-next {
  opacity: 0.6; }

.slideshow .slick-prev,
.slideshow .slick-next {
  z-index: 999999;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 40px;
  background-color: transparent;
  background-position: center center;
  background-size: 14px;
  opacity: 0.3; }
  .slideshow .slick-prev:hover,
  .slideshow .slick-next:hover {
    background-size: 18px;
    opacity: 1; }

.slideshow .slide_caption {
  line-height: 1em;
  padding: 20px 15px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  background-color: #1a1a1a;
  color: #fff; }

.slideshow .caption-title {
  margin: 0 0 0.5em;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1em; }

.slideshow .caption-text {
  margin: 0;
  font-size: 0.95rem;
  line-height: 1.2em; }

.slideshow .caption-text + .caption-text {
  margin-top: 0.5em; }

.slider_jumbo {
  padding: 0; }
  .slider_jumbo:hover .slick-prev,
  .slider_jumbo:hover .slick-next {
    opacity: 1; }
  .slider_jumbo .slick-prev,
  .slider_jumbo .slick-next {
    display: none !important;
    width: 30px;
    background-size: 10px;
    opacity: 1; }
    .slider_jumbo .slick-prev:hover,
    .slider_jumbo .slick-next:hover {
      background-size: 14px;
      opacity: 1; }
  .slider_jumbo .slick-prev {
    background-image: url("/_/images/icons/slide-white-prev.png"); }
  .slider_jumbo .slick-next {
    background-image: url("/_/images/icons/slide-white-next.png"); }
  .slider_jumbo .slide {
    padding: 0 5px; }
  .slider_jumbo .slide_wrap {
    border: 3px solid #fff; }
  .slider_jumbo .slide-placeholder {
    display: block;
    width: 100%; }
  @media (min-width: 768px) {
    .slider_jumbo {
      padding: 0 30px; }
      .slider_jumbo .slick-prev,
      .slider_jumbo .slick-next {
        display: block !important; }
      .slider_jumbo .slide-placeholder {
        opacity: 0; }
      .slider_jumbo .slide_wrap {
        background-size: cover; }
      .slider_jumbo .slide_8 .slide_wrap {
        background-image: url("/_/images/slides/jumbo/lg/8.jpg"); }
      .slider_jumbo .slide_7 .slide_wrap {
        background-image: url("/_/images/slides/jumbo/lg/7.jpg"); }
      .slider_jumbo .slide_6 .slide_wrap {
        background-image: url("/_/images/slides/jumbo/lg/6.jpg"); }
      .slider_jumbo .slide_5 .slide_wrap {
        background-image: url("/_/images/slides/jumbo/lg/5.jpg"); }
      .slider_jumbo .slide_4 .slide_wrap {
        background-image: url("/_/images/slides/jumbo/lg/4.jpg"); }
      .slider_jumbo .slide_3 .slide_wrap {
        background-image: url("/_/images/slides/jumbo/lg/3.jpg"); }
      .slider_jumbo .slide_2 .slide_wrap {
        background-image: url("/_/images/slides/jumbo/lg/2.jpg"); }
      .slider_jumbo .slide_1 .slide_wrap {
        background-image: url("/_/images/slides/jumbo/lg/1.jpg"); } }
  @media (min-width: 992px) {
    .slider_jumbo .slide_wrap {
      position: relative; }
    .slider_jumbo .slide_caption {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: linear-gradient(to right, rgba(0, 0, 0, 0.7) 62.5%, rgba(0, 0, 0, 0.95)); }
    .slider_jumbo .caption-title {
      font-size: 19px;
      font-size: 1.1875rem; }
      .slider_jumbo .caption-title .price {
        display: inline-block;
        margin: 0;
        float: right; } }

.slider_reviews .slick-prev,
.slider_reviews .slick-next {
  background-color: transparent;
  opacity: 1; }

.slider_reviews .slick-prev {
  background-image: url("/_/images/icons/slide-review-prev.png"); }

.slider_reviews .slick-next {
  background-image: url("/_/images/icons/slide-review-next.png"); }

.block {
  padding: 45px 15px; }
  .block:not(.content):not(.block_content):not(.page_title) .row {
    align-items: center;
    justify-content: center; }

.block_title {
  margin-bottom: 30px;
  text-align: center;
  line-height: 1em; }

.block-title {
  margin: 0; }

.block_content > *:last-child {
  margin-bottom: 0; }

.block_more {
  margin-top: 15px; }

.block_grill img {
  max-width: 240px;
  margin: 0 auto; }

.services_content {
  justify-content: center; }

.service_item {
  padding: 0 20px; }

.service_media img {
  display: block;
  width: 100%;
  max-width: 220px;
  margin: 0 auto;
  border-radius: 50%;
  border: 4px solid #fff;
  box-shadow: 0 0 0 4px #000; }

.service_content p {
  line-height: 1.5em; }

.block_about .list-hours li {
  margin-bottom: 0; }

@media (max-width: 991px) {
  .block_about .about_map {
    margin-bottom: 20px; }
  .block_about .about_hours {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .block_about .about_hours .about_company,
    .block_about .about_hours .about-hours {
      width: auto;
      padding: 0 15px; } }

@media (min-width: 992px) {
  .block_about .about_map {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .block_about .about_hours {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; } }

.block_announcement {
  border-bottom: 5px solid #f9f9f9;
  background-color: #000;
  color: #fff; }
  .block_announcement .gallery_lightbox img {
    padding: 2px;
    border: none;
    background-color: #fff; }
  .block_announcement .gallery_lightbox > a {
    margin-bottom: 1%; }
  @media (max-width: 991px) {
    .block_announcement .block_media {
      margin-top: 30px; } }

.block_socials {
  padding: 15px 15px 20px; }
  @media (min-width: 992px) {
    .block_socials {
      padding: 0 15px 15px; } }
  @media (max-width: 767px) {
    .block_socials .social-item:nth-of-type(1),
    .block_socials .social-item:nth-of-type(2) {
      margin-top: 5px;
      margin-bottom: 5px; } }

.social_content {
  font-weight: 600;
  font-style: italic;
  letter-spacing: -0.0275em;
  text-align: center; }

.social-item {
  display: inline-block;
  width: 120px;
  margin-left: 15px;
  line-height: 1em;
  vertical-align: middle; }
  .social-item.--first {
    margin-right: 0; }
  .social-item img {
    display: block;
    width: 100%;
    margin: 0 auto; }

.block_jumbo {
  padding: 60px 15px 30px;
  border-bottom: 4px solid #fff;
  box-shadow: 0 4px 0 0 #000;
  background: url("/_/images/layout/ribbon_top.png"), url("/_/images/layout/sunburst.svg"), #f99809;
  background-repeat: repeat-x, repeat, repeat;
  background-position: top center, center, center;
  background-size: auto, cover, cover; }

.jumbo_marketing {
  padding: 0; }
  @media (min-width: 768px) {
    .jumbo_marketing {
      padding: 0 10px; } }

.jumbo_alert_wrapper {
  margin-top: 15px;
  padding: 0 5px;
  width: 100%; }
  .jumbo_alert_wrapper .alert {
    font-size: 15px;
    font-size: 0.9375rem;
    line-height: 1em; }
    .jumbo_alert_wrapper .alert .alert-title {
      margin-bottom: 5px;
      font-size: 18px;
      font-size: 1.125rem;
      font-weight: 700; }
  .jumbo_alert_wrapper .alert-facebook {
    background-color: #4267B2;
    color: #fff; }
  @media (min-width: 768px) {
    .jumbo_alert_wrapper {
      padding: 0 45px; }
      .jumbo_alert_wrapper .alert_text {
        display: flex;
        flex-wrap: wrap;
        align-items: center; }
        .jumbo_alert_wrapper .alert_text h4, .jumbo_alert_wrapper .alert_text p {
          display: inline-block;
          width: auto;
          margin: 0 !important;
          line-height: 1em; }
        .jumbo_alert_wrapper .alert_text p {
          padding-left: 15px; } }
  @media (min-width: 992px) {
    .jumbo_alert_wrapper .alert_text h4, .jumbo_alert_wrapper .alert_text p {
      display: inline-block;
      width: auto; } }

.page_title {
  position: relative;
  padding: 90px 0 0;
  border-bottom: 4px solid #fff;
  box-shadow: 0 4px 0 0 #000;
  background: url("/_/images/layout/page-title.svg"), #f99809;
  background-size: cover, cover;
  color: #fff; }
  .page_title .title-wrap {
    z-index: 0;
    padding: 8px 15px;
    background-color: rgba(0, 0, 0, 0.2); }
  .page_title .page-title {
    z-index: 0;
    margin: 0;
    line-height: 1em;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4); }
  @media (min-width: 768px) {
    .page_title {
      padding-top: 120px; }
      .page_title:before, .page_title:after {
        z-index: 10;
        display: block;
        position: absolute;
        top: 0;
        width: 100%;
        height: 20px;
        content: "";
        background-image: url("/_/images/layout/ribbon_top.png");
        background-repeat: repeat-x; } }
  @media (min-width: 992px) {
    .page_title {
      padding-top: 180px; } }

.block_reviews {
  padding: 0; }

body.page_menu {
  position: relative; }

.block_menu {
  position: relative; }
  .block_menu .sidebar {
    padding-top: 0;
    padding-bottom: 45px; }
  .block_menu .r2t {
    z-index: 99500;
    position: fixed;
    bottom: 15px;
    right: 15px;
    width: 100%;
    margin: 0 auto; }
    .block_menu .r2t .button-return {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 55px;
      height: 55px;
      margin-left: auto;
      border-radius: 100%;
      background-color: #343a40;
      opacity: 0.6; }
      .block_menu .r2t .button-return img {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        transform: rotate(180deg);
        display: block;
        width: 60%;
        margin: 0 auto 5px; }
      .block_menu .r2t .button-return:hover {
        opacity: 1; }
  @media (min-width: 992px) {
    .block_menu .sidebar {
      position: relative; }
    .block_menu .nav-jump.affix {
      position: fixed;
      top: 20px;
      width: 220px; } }
  @media (min-width: 1200px) {
    .block_menu .nav-jump.affix {
      width: 265px; } }

#top {
  outline: none !important; }

.banner-top {
  padding: 10px 10px 0; }
  @media (min-width: 768px) {
    .banner-top {
      padding: 0;
      background-color: #ab0f14; }
      .banner-top .container {
        height: 5px; } }
  @media (min-width: 768px) {
    .banner-top {
      background: linear-gradient(to bottom, #ab0f14 98%, #fff 98%);
      overflow: hidden; }
      .banner-top .container {
        overflow: hidden;
        height: 45px;
        background: url("/_/images/layout/cityscape.svg") center 18px no-repeat;
        background-size: 100%;
        background-position: center bottom; } }

.banner {
  padding: 15px 15px 0;
  text-align: center;
  line-height: 1em; }
  .banner .logo-banner {
    margin-top: -50px; }
  @media (min-width: 768px) {
    .banner {
      padding: 30px 15px 15px; }
      .banner .row {
        align-items: flex-start !important; }
      .banner .banner_branding {
        flex: 0 0 33.33333%;
        max-width: 33.33333%; }
      .banner .logo-banner {
        margin-top: 0; }
      .banner .banner_contact {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        margin-top: 5px;
        text-align: right; }
      .banner .banner_nav {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
        margin-top: -60px;
        margin-left: auto; }
        .banner .banner_nav .banner-nav {
          margin-top: 0;
          text-align: right; } }
  @media (min-width: 992px) {
    .banner {
      /* 		.banner_branding { background-color: cyan; }
.banner_contact { background-color: green; }
.banner_nav { background-color: yellow; } */ }
      .banner .banner_contact {
        margin-top: 12px; }
      .banner .banner_nav {
        margin-top: -73px; } }

.banner_contact .contact-call {
  font-size: 26px;
  font-size: 1.625rem;
  line-height: 1em; }

.banner_contact .phone {
  font-size: inherit;
  line-height: 1em; }

.banner_contact .contact-note {
  font-size: 70%;
  line-height: 1em;
  letter-spacing: -0.0325em; }

@media (min-width: 768px) {
  .banner_contact .contact-note {
    display: inline-block;
    position: relative;
    top: -0.15em; }
    .banner_contact .contact-note:after {
      content: ": "; } }

.sidebar {
  padding-top: 30px; }
  .sidebar .form_quick {
    margin-bottom: 20px; }
  .flip .sidebar {
    padding-top: 0;
    padding-bottom: 30px; }
  @media (min-width: 768px) {
    .sidebar {
      padding-top: 0; } }

.site_info {
  position: relative;
  padding: 30px 0;
  text-align: center;
  border-top: 3px solid #fff;
  background-color: #000;
  color: #fff; }
  @media (min-width: 768px) {
    .site_info .info_logo {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      text-align: left; }
      .site_info .info_logo .logo-footer,
      .site_info .info_logo .company-call {
        display: inline-block;
        margin: 0 15px 0 0; }
    .site_info .info_copyright {
      text-align: right; } }

.info_copyright {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 1.3em; }
  .info_copyright a {
    font-weight: 600;
    line-height: 1em;
    text-decoration: none;
    color: #fff; }

.hidden {
  display: none !important; }

@media (max-width: 575px) {
  .hidden--xs {
    display: none !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .hidden--sm {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .hidden--md {
    display: none !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden--lg {
    display: none !important; } }

@media (min-width: 1200px) {
  .hidden--xl {
    display: none !important; } }

[class*="visible-"] {
  display: none !important; }

@media (max-width: 575px) {
  .visible--xs {
    display: block !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .visible--sm {
    display: block !important; } }

@media (min-width: 768px) and (max-width: 991px) {
  .visible--md {
    display: block !important; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .visible--lg {
    display: block !important; } }

@media (min-width: 1200px) {
  .visible--xl {
    display: block !important; } }

.db {
  display: block !important; }

.mt30 {
  margin-top: 30px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt5 {
  margin-top: 5px !important; }

.mt0 {
  margin-top: 0 !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb15 {
  margin-bottom: 15px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.pt0 {
  padding-top: 0 !important; }

.pt15 {
  padding-top: 15px !important; }

.pb0 {
  padding-bottom: 0 !important; }

.pb15 {
  padding-bottom: 15px !important; }

.no-transform {
  transform: none !important; }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0; }

.ff--serif {
  font-family: "Crimson Text", "Times New Roman", Georgia, Times, serif !important; }

.ff--sans {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important; }

.txt--lg {
  font-size: 115% !important; }

.txt--md {
  font-size: inherit !important; }

.txt--sm {
  font-size: 85% !important; }

.bw0 {
  font-weight: 400 !important; }

.bw1 {
  font-weight: 600 !important; }

.bw2 {
  font-weight: 800 !important; }

.hw0 {
  font-weight: 700 !important; }

.hw1 {
  font-weight: 700 !important; }

.hw2 {
  font-weight: 700 !important; }

.ls--sm {
  letter-spacing: -0.0375em; }

.ls--rg {
  letter-spacing: 0; }

.ls--lg {
  letter-spacing: 0.275em; }

.lh1 {
  line-height: 1.1em !important; }

.lh125 {
  line-height: 1.25em !important; }

.txt--left {
  text-align: left !important; }

.txt--right {
  text-align: right !important; }

.txt--center {
  text-align: center !important; }

.txt--up {
  text-transform: uppercase !important; }

.txt--cap {
  text-transform: capitalize !important; }

.color--text {
  color: #333 !important; }

.color--white {
  color: #fff !important; }

.color--primary {
  color: #ab0f14 !important; }

.color--secondary {
  color: #f99809 !important; }

.color--highlight {
  color: #000 !important; }

.color--accent {
  color: #786a4b !important; }

.color--link {
  color: #da1319 !important; }

.color--review {
  color: #fd7e14 !important; }

.color--success {
  color: #28a745 !important; }

.color--danger {
  color: #dc3545 !important; }

.color--warning {
  color: #ffc107 !important; }

.color--info {
  color: #0182C8 !important; }

.bg--text {
  background-color: #333 !important; }

.bg--white {
  background-color: #fff !important; }

.bg--owhite {
  background-color: #f5f5f5 !important; }

.bg--primary {
  background-color: #ab0f14 !important; }

.bg--secondary {
  background-color: #f99809 !important; }

.bg--highlight {
  background-color: #000 !important; }

.bg--highlight-dark {
  background-color: black !important; }

.bg--accent {
  background-color: #786a4b !important; }

.bg--accent-dark {
  background-color: #594e37 !important; }

.bg--link {
  background-color: #da1319 !important; }

.bg--review {
  background-color: #fd7e14 !important; }

.bg--success {
  background-color: #28a745 !important; }

.bg--danger {
  background-color: #dc3545 !important; }

.bg--warning {
  background-color: #ffc107 !important; }

.bg--info {
  background-color: #0182C8 !important; }

.bg--transparent {
  background-color: transparent !important; }

.bg--body {
  background-color: #f9f9f9 !important; }

.grdbg--text {
  /*	background: $arg;
	background:   linear-gradient(45deg, rgba(0,0,0,0.2) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 100%),
				  linear-gradient(135deg, rgba(0,0,0,0.1) 10%, transparent 45%, transparent 55%, rgba(0,0,0,0.1) 90%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba(255,255,255,0.22) 35%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 100%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 60%,rgba(255,255,255,0.22) 75%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
				  linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.3) 40%, rgba($white,0.3) 60%, rgba($white,0) 85%),
				  $arg;*/
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.2), transparent, rgba(255, 255, 255, 0.2));
  background-color: #333 !important; }

.grdbg--white {
  /*	background: $arg;
	background:   linear-gradient(45deg, rgba(0,0,0,0.2) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 100%),
				  linear-gradient(135deg, rgba(0,0,0,0.1) 10%, transparent 45%, transparent 55%, rgba(0,0,0,0.1) 90%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba(255,255,255,0.22) 35%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 100%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 60%,rgba(255,255,255,0.22) 75%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
				  linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.3) 40%, rgba($white,0.3) 60%, rgba($white,0) 85%),
				  $arg;*/
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.2), transparent, rgba(255, 255, 255, 0.2));
  background-color: #fff !important; }

.grdbg--primary {
  /*	background: $arg;
	background:   linear-gradient(45deg, rgba(0,0,0,0.2) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 100%),
				  linear-gradient(135deg, rgba(0,0,0,0.1) 10%, transparent 45%, transparent 55%, rgba(0,0,0,0.1) 90%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba(255,255,255,0.22) 35%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 100%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 60%,rgba(255,255,255,0.22) 75%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
				  linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.3) 40%, rgba($white,0.3) 60%, rgba($white,0) 85%),
				  $arg;*/
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.2), transparent, rgba(255, 255, 255, 0.2));
  background-color: #ab0f14 !important; }

.grdbg--secondary {
  /*	background: $arg;
	background:   linear-gradient(45deg, rgba(0,0,0,0.2) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 100%),
				  linear-gradient(135deg, rgba(0,0,0,0.1) 10%, transparent 45%, transparent 55%, rgba(0,0,0,0.1) 90%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba(255,255,255,0.22) 35%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 100%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 60%,rgba(255,255,255,0.22) 75%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
				  linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.3) 40%, rgba($white,0.3) 60%, rgba($white,0) 85%),
				  $arg;*/
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.2), transparent, rgba(255, 255, 255, 0.2));
  background-color: #f99809 !important; }

.grdbg--highlight {
  /*	background: $arg;
	background:   linear-gradient(45deg, rgba(0,0,0,0.2) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 100%),
				  linear-gradient(135deg, rgba(0,0,0,0.1) 10%, transparent 45%, transparent 55%, rgba(0,0,0,0.1) 90%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba(255,255,255,0.22) 35%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 100%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 60%,rgba(255,255,255,0.22) 75%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
				  linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.3) 40%, rgba($white,0.3) 60%, rgba($white,0) 85%),
				  $arg;*/
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.2), transparent, rgba(255, 255, 255, 0.2));
  background-color: #000 !important; }

.grdbg--accent {
  /*	background: $arg;
	background:   linear-gradient(45deg, rgba(0,0,0,0.2) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 100%),
				  linear-gradient(135deg, rgba(0,0,0,0.1) 10%, transparent 45%, transparent 55%, rgba(0,0,0,0.1) 90%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba(255,255,255,0.22) 35%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 100%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 60%,rgba(255,255,255,0.22) 75%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
				  linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.3) 40%, rgba($white,0.3) 60%, rgba($white,0) 85%),
				  $arg;*/
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.2), transparent, rgba(255, 255, 255, 0.2));
  background-color: #786a4b !important; }

.grdbg--review {
  /*	background: $arg;
	background:   linear-gradient(45deg, rgba(0,0,0,0.2) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 100%),
				  linear-gradient(135deg, rgba(0,0,0,0.1) 10%, transparent 45%, transparent 55%, rgba(0,0,0,0.1) 90%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba(255,255,255,0.22) 35%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 100%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 60%,rgba(255,255,255,0.22) 75%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
				  linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.3) 40%, rgba($white,0.3) 60%, rgba($white,0) 85%),
				  $arg;*/
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.2), transparent, rgba(255, 255, 255, 0.2));
  background-color: #fd7e14 !important; }

.grdbg--success {
  /*	background: $arg;
	background:   linear-gradient(45deg, rgba(0,0,0,0.2) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 100%),
				  linear-gradient(135deg, rgba(0,0,0,0.1) 10%, transparent 45%, transparent 55%, rgba(0,0,0,0.1) 90%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba(255,255,255,0.22) 35%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 100%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 60%,rgba(255,255,255,0.22) 75%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
				  linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.3) 40%, rgba($white,0.3) 60%, rgba($white,0) 85%),
				  $arg;*/
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.2), transparent, rgba(255, 255, 255, 0.2));
  background-color: #28a745 !important; }

.grdbg--danger {
  /*	background: $arg;
	background:   linear-gradient(45deg, rgba(0,0,0,0.2) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 100%),
				  linear-gradient(135deg, rgba(0,0,0,0.1) 10%, transparent 45%, transparent 55%, rgba(0,0,0,0.1) 90%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba(255,255,255,0.22) 35%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 100%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 60%,rgba(255,255,255,0.22) 75%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
				  linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.3) 40%, rgba($white,0.3) 60%, rgba($white,0) 85%),
				  $arg;*/
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.2), transparent, rgba(255, 255, 255, 0.2));
  background-color: #dc3545 !important; }

.grdbg--warning {
  /*	background: $arg;
	background:   linear-gradient(45deg, rgba(0,0,0,0.2) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 100%),
				  linear-gradient(135deg, rgba(0,0,0,0.1) 10%, transparent 45%, transparent 55%, rgba(0,0,0,0.1) 90%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba(255,255,255,0.22) 35%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 100%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 60%,rgba(255,255,255,0.22) 75%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
				  linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.3) 40%, rgba($white,0.3) 60%, rgba($white,0) 85%),
				  $arg;*/
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.2), transparent, rgba(255, 255, 255, 0.2));
  background-color: #ffc107 !important; }

.grdbg--info {
  /*	background: $arg;
	background:   linear-gradient(45deg, rgba(0,0,0,0.2) 0%, transparent 25%, transparent 75%, rgba(0,0,0,0.2) 100%),
				  linear-gradient(135deg, rgba(0,0,0,0.1) 10%, transparent 45%, transparent 55%, rgba(0,0,0,0.1) 90%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 20%,rgba(255,255,255,0.22) 35%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 100%),
				  //linear-gradient(45deg, rgba(255,255,255,0) 0%,rgba(255,255,255,0) 60%,rgba(255,255,255,0.22) 75%,rgba(255,255,255,0) 75%,rgba(255,255,255,0) 100%),
				  linear-gradient(135deg, rgba($white,0) 15%, rgba($white,0.3) 40%, rgba($white,0.3) 60%, rgba($white,0) 85%),
				  $arg;*/
  background-image: linear-gradient(135deg, rgba(0, 0, 0, 0.2), transparent, rgba(255, 255, 255, 0.2));
  background-color: #0182C8 !important; }
