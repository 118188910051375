#top { outline: none !important; }

.banner-top {
	padding: 10px 10px 0;
	@include media-breakpoint-up(md) {
		padding: 0;
		background-color: theme(primary, base);
		.container { height: 5px; }
	}
	@include media-breakpoint-up(md) {
		background: linear-gradient(to bottom, theme(primary, base) 98%, $white 98%);
		overflow: hidden;
		.container {
			overflow: hidden;
			height: 45px;
			background: url("/_/images/layout/cityscape.svg") center 18px no-repeat;
			background-size: 100%;
			background-position: center bottom;
		}
	}
}

.banner {

	padding: 15px 15px 0;
	text-align: center;
	line-height: 1em;

	.logo-banner { margin-top: -50px; }

	@include media-breakpoint-up(md) {
		padding: 30px 15px 15px;
		.row { align-items: flex-start !important; }
		.banner_branding { @include make-col(4); }
			.logo-banner { margin-top: 0; }
		.banner_contact {
			@include make-col(8);
			margin-top: 5px;
			text-align: right;
		}
		.banner_nav {
			@include make-col(8);
			margin-top: -60px;
			margin-left: auto;
			.banner-nav {
				margin-top: 0;
				text-align: right;
			}
		}
	}

	@include media-breakpoint-up(lg) {
/* 		.banner_branding { background-color: cyan; }
.banner_contact { background-color: green; }
.banner_nav { background-color: yellow; } */

		.banner_contact { margin-top: 12px; }
		.banner_nav { margin-top: -73px; }

	}

	@include media-breakpoint-up(xl) {}

}

	.banner_contact {
		.contact-call { @include font-size(26); line-height: 1em; }
		.phone { font-size: inherit; line-height: 1em; }
		.contact-note { font-size: 70%; line-height: 1em; letter-spacing: -0.0325em; }
		@include media-breakpoint-up(md) {
			.contact-note {
				display: inline-block; 
				position: relative;
				top: -0.15em;
				&:after { content: ": "; }
			}
		}
	}