.block {
	padding: 45px 15px;
	&:not(.content, .block_content, .page_title) .row {
		align-items: center;
		justify-content: center;
	}
}

.block_title {
	margin-bottom: 30px;
	text-align: center;
	line-height: 1em;
}

	.block-title {
		@extend %h2;
		margin: 0;
	}

.block_content {
	> *:last-child { margin-bottom: 0; }
}

.block_more {
	margin-top: 15px;
}

.block_grill {
	img {
		max-width: 240px;
		margin: 0 auto;
	}
}


.block_services {}

	.services_content {
		justify-content: center;
	}

		.service_item { padding: 0 20px; }

			.service_media img {
				display: block;
				width: 100%;
				max-width: 220px;
				margin: 0 auto;
				border-radius: 50%;
				border: 4px solid $white;
				box-shadow: 0 0 0 4px theme(highlight, base);
			}

			.service_content {
				p { line-height: 1.5em; }
			}