.block_announcement {

	border-bottom: 5px solid $body-bg;
	background-color: #000;
	color: $white;

    .gallery_lightbox {
        img {
            padding: 2px;
            border: none;
            background-color: $white;
        }
        > a { margin-bottom: 1%; }
    }

	@include media-breakpoint-down(md) {
		.block_media {
			margin-top: 30px;
		}
	}

}