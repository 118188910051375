.slider_jumbo {
    
	padding: 0;

    &:hover {
        .slick-prev,
        .slick-next { opacity: 1; }
    }

	.slick-prev,
    .slick-next {
        display: none !important;
        width: 30px;
        background-size: 10px;
        opacity: 1;
        &:hover {
            background-size: 14px;
            opacity: 1;
        }
    }

        .slick-prev { background-image: url("/_/images/icons/slide-white-prev.png"); }
        .slick-next { background-image: url("/_/images/icons/slide-white-next.png"); }

	.slide {
		padding: 0 5px;
	}

    .slide_wrap {
        border: 3px solid $white;
    }

	.slide-placeholder {
        display: block;
        width: 100%;
	}

    @include media-breakpoint-up(md) {
        padding: 0 30px;
        .slick-prev,
        .slick-next { display: block !important; }

        .slide-placeholder { opacity: 0; }

        .slide_wrap { background-size: cover; }

        $slides: 8;

        @while $slides > 0 {
            .slide_#{$slides} .slide_wrap {
                background-image: url("/_/images/slides/jumbo/lg/#{$slides}.jpg");
            }
        
            $slides: $slides - 1;

        }


    }

    @include media-breakpoint-up(lg) {
        .slide_wrap { position: relative; }
        .slide_caption {
            position: absolute;
            bottom: 0;
            width: 100%;
            background: linear-gradient(to right, rgba(black, 0.7) 62.5%, rgba(black, 0.95));
        }
        .caption-title {
            @include font-size(19);
            .price {
                display: inline-block;
                margin: 0;
                float: right;
            }
        }
    }

}