.page_title {

	position: relative;
	padding: 90px 0 0;
	border-bottom: 4px solid $white;
	box-shadow: 0 4px 0 0 theme(highlight, base);
	background:		url("/_/images/layout/page-title.svg"),
					theme(secondary, base);
	background-size: cover, cover;
	color: $white;

	.title-wrap {
		z-index: 0;
		padding: 8px 15px;
		background-color: rgba(black, 0.2);
	}

	.page-title {
		z-index: 0;
		margin: 0;
		line-height: 1em;
		text-shadow: 0 1px 1px rgba(0, 0, 0, 0.4);
	}

	@include media-breakpoint-up(md) {
		padding-top: 120px;
		&:before,
		&:after {
			z-index: 10;
			display: block;
			position: absolute;
			top: 0;
			width: 100%;
			height: 20px;
			content: "";
			background-image: url("/_/images/layout/ribbon_top.png");
			background-repeat: repeat-x;
		}
	}

	@include media-breakpoint-up(lg) {
		padding-top: 180px;
	}

}
