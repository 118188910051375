@mixin alert($bg: #ddd, $color: $color-text) {
	background-color: $bg;
	color: $color;
}

.alert {
	
	@include alert();
	padding: 18px 15px;
	border-radius: 3px;
	
	@include make-flex;
	align-items: center;
	.alert_body { width: 93%; order: 1; }
	.alert_button {
		width: 7%;
		order: 2;
		.button_close { width: 26px; height: 26px; float: right; }
	}

	&.--has-conversion {
		padding: 10px 15px;
	}

}

	.alert-primary { @include alert($color-primary); }
	.alert-secondary { @include alert($color-secondary); }
	.alert-highlight { @include alert($color-highlight, $white); }
	.alert-accent { @include alert($color-accent); }
	.alert-success { @include alert($color-success); }
	.alert-danger { @include alert($color-danger); }
	.alert-info { @include alert($color-info); }
	.alert-warning { @include alert($color-warning); }
	.alert-link { @include alert($color-link); }

	.alert-title {
		margin-top: 0;
		margin-right: 34px;
		font-family: $font-family-body;
		font-weight: $body-weight-bold;
		letter-spacing: -0.0325em;
	}