.review {

	padding: 0 60px;

	cite {
		display: block;
		margin-top: 1em;
		@include font-size(15);
		font-style: normal;
		line-height: 1em;
		text-align: center;
		img {
			display: block;
			width: 120px;
			margin: 0 auto 5px;
		}
	}

}