
.faq_item {

	margin-bottom: 1em;
	border: 1px solid #ccc;
	background-color: $white;
	counter-increment: section;

	&:nth-child(even) {
		.faq_title { background-color: #f0f0f0; }
	}

	.faq_title {
		h2 {
			margin: 0;
			padding: 0.5em;
			font-size: 1.2rem;
			letter-spacing: 0;
			&:hover {
				color: theme(primary, light);
				//color: $white;
				cursor: pointer;
			}
			&:before {
				display: inline-block;
				width: 42px;
				content: counter(section) ". ";
			}
		}
	}

		&.open .faq_title h2 {
			background-color: theme(primary, base);
			color: $white;
		}

	.faq_body {
		padding: 1em 1em 0.5em;
		p, ul, ol { margin-bottom: 0.5em; line-height: 1.4em }
		ul, ol {
			li:not(:last-child) { margin-bottom: 0.25em; }
		}
	}

}