.sidebar {

	padding-top: 30px;

	.form_quick { margin-bottom: 20px; }

	.flip & {
		padding-top: 0;
		padding-bottom: 30px;
	}

	@include media-breakpoint-up(md) {
		padding-top: 0;
	}

}