.hours {
	display: block;
	@include font-size(14);
	line-height: 1em;
}

.contact-hours {

	.hours-now {
		position: relative;
		display: inline-block;
		text-decoration: none;
		color: theme(text, base);
		outline: none !important;
		@include media-breakpoint-up(lg) {
			color: $white;
		}
	}	

		.hours-now-wrap {
			display: inline-block;
			padding: 10px 15px 10px 0;
			//@include font-size(16);
			background: url("/_/images/icons/caret-hours.png") right center no-repeat;
			background-size: 12px;
			@include media-breakpoint-up(lg) {
				background-image: url("/_/images/icons/caret.png");
			}
		}

	.list-hours {
		display: none;
		width: auto;
		margin: 0 auto 15px;
		padding: 10px;
		border: 1px solid #ccc;
		background-color: $white;
		color: theme(text, base);
		@include media-breakpoint-up(md) {
			position: absolute;
			z-index: 1;
			margin-top: 3px;
		}
	}

}

	.list-hours {
		@extend %list_unstyled;
		text-align: left;
		li {
			margin: 0;
			padding: 7px 10px;
			white-space: nowrap;
			&:nth-child(even) { background-color: #eee; }
			&.active { color: theme(primary, base); }
			&:not(:last-child) { border-bottom: 1px dotted #ccc; }
		}
		b {
			display: inline-block;
			width: 100px;
		}

	}