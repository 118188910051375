.block_about {

	.list-hours li {
		margin-bottom: 0;
	}

	@include media-breakpoint-down(md) {
		.about_map { margin-bottom: 20px; }
		.about_hours {
			@include make-flex;
			justify-content: center;
			.about_company,
			.about-hours {
				width: auto;
				padding: 0 15px;
			}

		}
	}

	@include media-breakpoint-up(lg) {
		.about_map { @include make-col(8); }
		.about_hours { @include make-col(4); }
	}

}