.nav-jump {
	margin-bottom: 15px;
	background-color: $white;
	li {
		display: inline-block;
		margin-bottom: 8px;
	}
	@include media-breakpoint-up(lg) {
		li {
			display: block;
			.btn { display: block; }
			&.active .btn {
				border: none;
				background-color: transparent;
				@include transform(scale(1));
				&.btn-primary { color: theme(primary, base); }
				&.btn-secondary { color: theme(secondary, base); }
			}
		}
	}
}

.table-menu {
	margin-bottom: 0;
	td {
		border-top: none;
		border-bottom: 1px dotted #ccc;
		padding: 15px;
		line-height: 1.5em;
	}
	thead .mt-title { vertical-align: middle; }
	.mt-price { white-space: nowrap; text-align: right; }
	.menu-item-price { float: none; }
	tbody > tr:last-of-type td { border-bottom: none; }

	&.table--sm {
		td { padding: 10px; }
	}

}

.menu_section {
	padding: 15px 0;
	outline: none !important;
	&:nth-child(even) .menu-section-title { color: theme(primary, base); }
	@include media-breakpoint-up(lg) { padding: 45px 0 0; }
}

	.menu-section-title {
		margin: 0 0 15px;
		padding-bottom: 15px;
		font-weight: $heading-weight-bolder;
		text-transform: uppercase;
		border-bottom: 1px solid #ccc;
		color: theme(secondary, base);
	}

.menu-list {
	.menu-item {
		padding: 15px;
		margin: 0 !important;
		line-height: 1.5em;
	}
	.menu-item:nth-child(even) { background-color: #eee; }
	.menu-item:first-child { padding-top: 0; }
	.menu-item:not(:last-child) {
		border-bottom: 1px dotted #ccc;
	}
}

	.menu-item-title {
		@include font-size(18);
	}

	.menu-item-price {
		display: block;
		@include font-size(18);
		font-weight: $body-weight-bold;
		color: theme(success, base);
		@include media-breakpoint-up(md) {
			display: inline-block;
			float: right;
		}
	}

	.menu-item-description {
		margin: 0;
		@include font-size(15);
		font-style: italic;
		line-height: 1.5em;
		@include media-breakpoint-up(md) {
			//margin-right: 80px;
			margin-top: 5px;
			max-width: 540px;
		}
	}

	.menu-addons {
		margin: 5px 0 0 0;
		padding-top: 5px;
		li:before {
			font-weight: $body-weight-bolder;
			content: "- ";
		}
		.addon-price {
			font-weight: $body-weight-bold;
			color: theme(success, base);
			&:before {
				display: inline-block;
				padding-right: 5px;
				font-weight: $body-weight-normal;
				color: theme(text, base);
				content: ".....";
			}
		}
	}