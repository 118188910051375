$nav-mobile-bg: 			theme(primary, base) !default;
$mobile-link-bg: 			transparent !default;
$mobile-link-color:			$white !default;
$mobile-hover-bg: 			theme(secondary, base) !default;
$mobile-hover-color: 		$white !default;
$mobile-active-bg: 			$white !default;
$mobile-active-color: 		theme(primary, base) !default;

@mixin offcanvas {
	display: none;
	&.in { display: block; }
}

.nav_mobile {

	@include offcanvas;
	position: fixed;
	z-index: 999999;
	top: 0; right: auto;
	bottom: 0; left: 0;
	overflow-y: auto;
	width: 80%;
	max-width: 320px;
	height: 100%;
	padding: 45px 15px 0;
	border-right: 3px solid $white;
	box-shadow: 2px 0 5px 0 rgba(0,0,0,0.5);
	background-color: $nav-mobile-bg;
	color: $white;

	@include media-breakpoint-up(md) { display: none !important; }

	hr {
		margin: 15px 0;
		background-color: $white;
	}

	.nav > .nav-item:not(:last-child) { border-bottom: 1px solid rgba(0,0,0,0.35); }
	.nav > .nav-item:not(:first-child) { border-top: 1px solid rgba(255,255,255,0.5); }

	.nav > .nav-item {

		&.active .nav-link {
			background-color: $mobile-active-bg;
			color: $mobile-active-color;
			&:hover, &:focus {
				background-color: $mobile-active-bg;
				color: $mobile-active-color;
			}
		}

		&.open {
			> .nav-link {
				border-bottom: 1px solid rgba(0,0,0,0.35);
				background-color: theme(secondary, base);
			}
			.dropdown-nav { border-top: 1px solid rgba(255,255,255,0.5); }
		}

	}	

		.nav-item > .nav-link {
			padding: 15px;
			font-weight: $body-weight-bold;
			background-color: $mobile-link-bg;
			color: $mobile-link-color;
			&:hover, &:focus {
				outline: none;
				background-color: $mobile-hover-bg;
				color: $mobile-hover-color;
			}
		}

	.dropdown-nav {
		background-color: theme(secondary, base);
		padding: 0;

		.nav-item:not(:last-child) { border-bottom: 1px solid rgba(0,0,0,0.35); }
		.nav-item:not(:first-child) { border-top: 1px solid rgba(255,255,255,0.5); }

		.nav-link { 
			letter-spacing: -0.0325em;
			&:hover {
				background-color: theme(secondary, dark);
				color: $white;
			}
		}

	}

	.mobile-toggle {

		font-size: 1.2em;
		color: #fff;
		text-decoration: none;

		img {
			display: inline-block;
			width: 0.8em;
			margin-right: 10px;
		}

	}

}

