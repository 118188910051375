.form_quick {

	@include media-breakpoint-only(md) {

		.form_left,
		.form_right {
			float: left;
			width: 49%;
		}

		.form_left { margin-right: 2%; }

		.form_clear { clear: both; }

	}

}

.page_contact .form_quick {

	@include media-breakpoint-up(md) {

		.form_left,
		.form_right {
			float: left;
			width: 49%;
		}

		.form_left { margin-right: 2%; }

		.form_clear { clear: both; }

	}

}

.sidebar .form_quick {
	padding: 15px;
	border: 1px solid #ccc;
	background-color: #f0f0f0;
	.form-title {
		margin-bottom: 15px;
	}
}