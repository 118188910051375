.directions_map {
	border: 1px solid #ccc;

	@include media-breakpoint-up(lg) {
		.contact_map { padding-bottom: 125%; }
	}

	@include media-breakpoint-up(xl) {
		.contact_map { padding-bottom: 90%; }
	}

}

.contact_map {
	border: 5px solid $white;
}

.g_map {
	position: relative;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	&:hover {
		cursor: pointer;
		p { opacity: 1 }
	}

	p {
		position: absolute;
		top: 45%;
		width: 100%;
		padding: 0.5em;
		@include font-size(20);
		font-family: $font-family-headings;
		font-weight: $heading-weight-bold;
		text-align: center;
		text-transform: uppercase;
		text-shadow: 0 1px 1px rgba(black, 0.4);
		background-color: theme(secondary, opac);
		color: $white;
		opacity: 0;
	}

}



	.about-map {

		background-image: url("/_/images/maps/sm.webp");
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;

		.g_map,
		.embed-responsive-item { padding-bottom: 56.25%; }
		
		@include media-breakpoint-up(md) {
			background-image: url("/_/images/maps/lg.webp");
		}
		
	}