.site_info {
	position: relative;
	padding: 30px 0;
	text-align: center;
	border-top: 3px solid $white;
	background-color: theme(highlight, base);
	color: $white;

	@include media-breakpoint-up(md) {
		.info_logo {
			@include make-flex;
			align-items: center;
			text-align: left;
			.logo-footer,
			.company-call {
				display: inline-block;
				margin: 0 15px 0 0;
			}
		}
		.info_copyright { text-align: right; }
	}

}

.info_copyright {

	@include font-size(14);
	line-height: 1.3em;
	a {
		font-weight: $body-weight-bold;
		line-height: 1em;
		text-decoration: none;
		color: $white;
	}

}