.banner-nav {

	overflow: hidden;
	margin-top: 15px;

	.nav-item {
		display: none;
		&.nav-menu_breakfast,
		&.nav-menu_lunch {
			display: inline-block;
		}
		&.nav-menu_lunch { margin-left: 10px;  }
		&.active {
			.nav-link {
				background-color: transparent;
				color: theme(primary, base);
			}
		}
	}

	.nav-link {
		padding: 12px 20px;
		@include font-size(18);
		font-family: $font-family-headings;
		font-weight: $heading-weight-bold;
		border-radius: 4px;
		background-color: theme(highlight, base);
		color: $white;
		&:hover {
			background-color: theme(secondary, base);
		}
	}

	@include media-breakpoint-up(md) {
		text-align: center;
		.nav-item {
			display: inline-block;
			padding: 0 0 0 6px;
			&.nav-menu_lunch { margin-left: 0; }
		}
		.nav-link { padding: 10px 15px; }
	}

	@include media-breakpoint-up(lg) {
		margin-top: 0;
	}

}