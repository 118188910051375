body {

	min-width: 360px;


	//background-color: #A06135;
/*	background: linear-gradient(
		135deg,
		darken(#A06135, 17%),
		lighten(#A06135, 10%) 80%
	);*/

/*	@include media-breakpoint-up(md) {

		height: 100%;
		background: url("/_/images/layout/logo-mark.png"),
					url("/_/images/layout/body.png");
		background-repeat: no-repeat, repeat;
		background-size: 300px, auto;
		background-position: -50px 30px, left top;
		padding-bottom: 143px;

		.site_info {
			position: absolute;
			bottom: 0;
			width: 100%;
		}

	}*/

	@include media-breakpoint-up(lg) {
		background-size: 350px, auto;
		background-position: -55px -25px, left top;
	}

}

#directions,
#hours {
	outline: none !important;
	box-shadow: none !important;
}