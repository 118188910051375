// 200x69
// 240x83
// 270x93
// 300x103
// 360x124
// 480x165
// 640x220

.logo-banner {
	display: block;
	max-width: 270px;
	width: 100%;
	margin: 0 auto 15px;
	line-height: 0em;
}

	.logo-footer {
		display: block;
		max-width: 200px;
		margin: 0 auto;
	}